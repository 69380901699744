<template>
  <div class="el-select-tree">
    <el-popover
      ref="elPopover"
      v-model="visible"
      transition="el-zoom-in-top"
      popper-class="el-select-tree__popover"
      trigger="click"
      :disabled="disabled"
      :placement="placement"
      :width="popoverWidth"
      @after-enter="handleScroll()"
    >
      <el-input
        v-if="filterable"
        ref="filterInput"
        size="mini"
        class="el-select-tree__filter"
        style="width: 100%"
        v-model="filterText"
      >
        <el-button v-if="filterText == ''" slot="append" size="mini" icon="el-icon-search"></el-button>
        <el-button v-else slot="append" size="mini" icon="el-icon-close" @click="clearFilterText()"></el-button>
      </el-input>
      <el-scrollbar
        wrap-class="el-select-dropdown__wrap"
        view-class="el-select-dropdown__list"
        ref="scrollbar"
      >
        <el-tree
          ref="elTree"
          class="el-select-tree__list"
          :default-expanded-keys="defaultExpandedKeys"
          :show-checkbox="multiple"
          :expand-on-click-node="multiple"
          :style="{ 'min-width': minWidth + 'px' }"
          @node-click="nodeClick"
          @check-change="checkChange"
          @transitionend.native="$refs.elPopover.updatePopper()"
          :data="data"
          :props="props"
          :node-key="propsValue"
          :default-expand-all="defaultExpandAll"
          :check-strictly="checkStrictly"
          :lazy="lazy"
          :load="load"
          :icon-class="iconClass"
          :indent="indent"
          :accordion="accordion"
          :filter-node-method="filterNodeMethod"
          :auto-expand-parent="autoExpandParent"
          :render-content="renderContent"
          :render-after-expand="renderAfterExpand"
        >
          <div
            class="el-select-tree__item"
            slot-scope="{ data }"
            :class="treeItemClass(data)"
          >
            {{ data[propsLabel] }}
          </div>
        </el-tree>
      </el-scrollbar>

      <!-- trigger input -->
      <el-input
        v-model="selectedLabel"
        ref="reference"
        slot="reference"
        readonly
        :validate-event="false"
        :size="size"
        :class="{
          'is-active': visible,
          'is-selected': selectedLabel,
          'is-clearable': clearable
        }"
        :disabled="disabled"
        :placeholder="placeholder"
      >
        <i
          v-if="clearable"
          @click.stop="clear()"
          slot="suffix"
          class="el-input__icon el-input__icon-close el-icon-circle-close"
        ></i>
        <i
          slot="suffix"
          class="el-input__icon el-input__icon-arrow-down el-icon-arrow-down"
        ></i>
      </el-input>
    </el-popover>
  </div>
</template>

<script>
import Vue from 'vue'
import Emitter from 'element-ui/lib/mixins/emitter'
// import {
//   addResizeListener,
//   removeResizeListener
// } from 'element-ui/lib/utils/resize-event';
export default {
  name: 'ElSelectTree',
  mixins: [Emitter],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // [el-tree] forwarding parameters https://element.eleme.io/#/zh-CN/component/tree#attributes
    data: {
      type: Array,
      default () {
        return []
      }
    },
    props: {
      type: Object,
      default () {
        return {
          value: 'value',
          label: 'label',
          children: 'children',
          disabled: 'disabled',
          isLeaf: 'isLeaf'
        }
      }
    },
    checkStrictly: Boolean,
    nodeKey: String,
    defaultExpandAll: Boolean,
    lazy: Boolean,
    load: Function,
    iconClass: String,
    indent: Number,
    accordion: Boolean,
    filterable: Boolean,
    filterNodeMethod: {
      type: Function,
      default: (value, data) => {
        if (!value) {
          return true
        }
        return data.label.indexOf(value) !== -1
      }
    },
    autoExpandParent: {
      type: Boolean,
      default: true
    },
    renderContent: Function,
    renderAfterExpand: Boolean,
    // [el-tree] forwarding parameters end
    clearable: Boolean,
    placeholder: {
      type: String,
      default: 'Сонгох'
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    size: {
      type: String,
      default: Vue.prototype.$ELEMENT ? Vue.prototype.$ELEMENT.size : ''
    },
    disabled: Boolean,
    multiple: Boolean,
    value: {
      type: [Number, String, Array],
      default: ''
    },
    popoverWidth: Number
  },
  computed: {
    propsValue () {
      return this.nodeKey || this.props.value || 'value'
    },
    propsLabel () {
      return this.props.label || 'label'
    },
    propsIsLeaf () {
      return this.props.isLeaf || 'isLeaf'
    },
    defaultExpandedKeys () {
      return Array.isArray(this.value)
        ? this.value
        : this.value || this.value === 0
          ? [this.value]
          : []
    }
  },
  data () {
    return {
      visible: false,
      selectedLabel: '',
      filterText: '',
      minWidth: 0
    }
  },
  methods: {
    valueChange (value, node) {
      this.$emit('change', value, node)
    },
    clear () {
      this.visible = false
      if (this.multiple) {
        this.valueChange([])
        this.$nextTick(() => {
          this.$refs.elTree.setCheckedKeys([])
        })
      } else {
        this.valueChange('')
      }
      this.$emit('clear')
    },
    // 触发滚动条的重置
    handleScroll () {
      this.$refs.scrollbar && this.$refs.scrollbar.handleScroll()
    },
    nodeClick (data, node, component) {
      const children = data[this.props.children]
      const value = data[this.propsValue]
      if (
        ((children && children.length) ||
          (this.lazy && !data[this.propsIsLeaf])) &&
        !this.checkStrictly
      ) {
        component.handleExpandIconClick()
      } else if (!this.multiple && !data.disabled) {
        if (value !== this.value) {
          this.valueChange(value, data)
          this.selectedLabel = data[this.propsLabel]
        }
        this.visible = false
      }
    },
    checkChange () {
      const elTree = this.$refs.elTree
      const leafOnly = !this.checkStrictly
      const keys = elTree.getCheckedKeys(leafOnly)
      const nodes = elTree.getCheckedNodes(leafOnly)
      this.valueChange(keys, nodes)
      this.setMultipleSelectedLabel()
    },
    setSelected () {
      this.$nextTick(() => {
        const elTree = this.$refs.elTree
        if (this.multiple) {
          elTree.setCheckedKeys(this.value)
          this.setMultipleSelectedLabel()
        } else {
          const selectedNode = elTree.getNode(this.value)
          this.selectedLabel = selectedNode
            ? selectedNode.data[this.propsLabel]
            : ''
        }
      })
    },
    setMultipleSelectedLabel () {
      const elTree = this.$refs.elTree
      const selectedNodes = elTree.getCheckedNodes(!this.checkStrictly)
      this.selectedLabel = selectedNodes
        .map((item) => item[this.propsLabel])
        .join(',')
    },
    treeItemClass (data) {
      return {
        'is-selected': this.multiple
          ? false
          : data[this.propsValue] === this.value,
        'is-disabled': data.disabled
      }
    },
    handleResize () {
      // set the `tree` default `min-width`
      // border's width is 2px
      this.minWidth = this.$el.clientWidth - 2
    },
    clearFilterText () {
      this.filterText = ''
    }
  },
  watch: {
    value () {
      this.setSelected()
      // trigger parent `el-form-item` validate event
      this.dispatch('ElFormItem', 'el.form.change')
    },
    data () {
      this.setSelected()
    },
    filterText (val) {
      this.$refs.elTree.filter(val)
    }
  },
  created () {
    if (this.multiple && !Array.isArray(this.value)) {
      throw new Error(
        '[el-select-tree] props `value` must be Array if use multiple!'
      )
    }
  },
  mounted () {
    this.setSelected()
    // addResizeListener(this.$el, this.handleResize);
  },
  beforeDestroy () {
    if (this.$el && this.handleResize) {
      // removeResizeListener(this.$el, this.handleResize);
    }
  }
}
</script>
