<template>
  <el-dialog
  :visible.sync="isShow"
  width="450px"
  ref="cancelDialogTokTok"
  @open="onOpenDialog()"
  @before-close="mCloseFunction()"
  @close="mCloseFunction()"
  >
  <!-- @before-close="mCloseFunction()"
  @close="mCloseFunction()"
  :before-close="mCloseFunction()" -->
    <div class="dialog-body">
      <div class="productInfo">
        <h3 style="text-align:center;"><i class="fas fa-ban"></i>&nbsp;&nbsp;Захиалга цуцлах</h3>
        <el-row style="margin-top: 25px">
          <el-col :span="10"><h3>Захиалгын дугаар:</h3></el-col>
          <el-col :span="14"><el-input v-model="orderData.order_number" :disabled="true"></el-input></el-col>
        </el-row>
        <el-row style="margin-top: 25px">
          <el-col :span="10"><h3>Захиалгын үнэ:</h3></el-col>
          <el-col :span="14"><el-input v-model="orderData.pay_total" :disabled="true"></el-input></el-col>
        </el-row>
        <el-form :model="payload" :rules="rules" ref="cancelTokTok" >
          <el-row style="margin-top: 25px">
            <el-col :span="10"><h3>Шалтгаан:</h3></el-col>
            <el-col :span="14">
              <el-form-item prop="reason">
                    <tree-selector v-model="payload.reason" :data="reasons" :props="defaultProps" :clearable="true" :filterable="true" :filterNodeMethod="reasonFilter" />
                <!-- <el-select v-model="payload.reason"> -->
                    <!-- <el-option :value="'Тест хийсэн'" :label="'Тест хийсэн'"></el-option> -->
                    <!-- <el-option :value="item" :label="item"></el-option>
                    <el-option :value="item" :label="item"></el-option>
                    <el-option :value="item" :label="item"></el-option> -->
                  <!-- </el-select> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-top: 25px">
            <el-col :span="10"><h3>Тайлбар:</h3></el-col>
            <el-col :span="14">
              <el-form-item prop="desc">
                <el-input v-model="payload.desc" type="textarea"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <span slot="footer">
      <el-button type="danger" style="float: left" @click="cancelOrder()">Цуцлах</el-button>
      <el-button type="info" @click="mCloseFunction()">Гарах</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _service from '../../../../helpers/services'
import { getUser, getUserName } from '../../../../utils/auth'
import { showNotif } from '@/helpers/helper.js'
import TreeSelector from './tree'
export default {
  name: 'pdCancelOrder',
  props: {
    closeFunction: {
      type: Function
    },
    isShow: {
      type: Boolean,
      default: false
    },
    orderData: {
      type: Object
    }
  },
  components: {
    TreeSelector
  },
  data: function () {
    return {
      user_name: '',
      payload: {
        reason: '',
        desc: ''
      },
      rules: {
        reason: { required: true, message: 'Цуцлах шалтгаан сонгоно уу', trigger: 'blur' },
        desc: { required: true, message: 'Тайлбар бичнэ үү', trigger: 'blur' }
      },
      reasons: [],
      defaultProps: {
        parent: 'parent_id',
        value: 'name',
        label: 'name',
        children: 'children'
      }
    }
  },
  methods: {
    getReasonsList () {
      _service.getReasonsList().then(response => {
        if (response.status === 'success') {
          response.response.forEach(el => {
            el.label = el.name
            el.children.forEach(child => {
              child.label = child.name
            })
            this.reasons.push(el)
          })
        } else {
          this.$notify.error({
            title: 'Алдаа: ' + response.error.code,
            message: response.error.message
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: 'Алдаа: цуцлах шалтгаан авах',
          message: error.message
        })
      })
    },
    cancelOrder () {
      const self = this
      // Цуцлах хүсэлт
      this.$refs.cancelTokTok.clearValidate()
      this.$refs.cancelTokTok.validate(async (valid) => {
        if (valid) {
          var loadingInstance = this.$loading(
            {
              target: self.$refs.cancelDialogTokTok.$el.querySelector('.el-dialog')
            })
          const tempUserName = await getUserName()
          const body = {
            reason: this.payload.reason,
            desc: this.payload.desc,
            user_name: tempUserName
          }
          getUser().then(() => {
            _service.orderCancel(this.orderData.id, body).then(response => {
              loadingInstance.close()
              if (response.status === 'success') {
                showNotif('success', 'Амжилттай', response.message)
                self.mCloseFunction()
              } else showNotif('warning', 'Алдаа гарлаа', response.data.error.message)
            }).catch(error => {
              loadingInstance.close()
              console.log('error', error)
              self.$notify.error({
                title: 'Алдаа',
                message: error.message
              })
            })
          })
        } else {
          return false
        }
      })
    },
    reasonFilter (value, data) {
      if (!value) {
        return true
      }
      return data.name.indexOf(value) !== -1
    },
    onOpenDialog () {
      this.getReasonsList()
    },
    mCloseFunction () {
      this.payload = {
        reason: '',
        desc: ''
      }
      this.closeFunction()
    }
  }

}
</script>

<style>

</style>
