<template>
  <div class="grid-content bg-purple" v-if="orderList">
      <div>
        <div class="content-header mt20">
          <h3 class="text-uppercase mb20 mt0" >
            TOKTOKMALL Одоогийн Захиалгууд
          </h3>
        </div>
        <el-tabs v-model="currentTab" type="border-card" v-if="!loading">
          <el-tab-pane name="prepareOrders">
            <span slot="label" >Бэлтгэж байгаа - {{ prepareOrdersCount }}</span>
          </el-tab-pane>
          <el-tab-pane name="driverOutOrders">
            <span slot="label" >Хүргэлтэнд гарсан - {{ driverOutOrdersCount }}</span>
          </el-tab-pane>
          <el-tab-pane name="cancelledOrders">
            <span slot="label" >Цуцлагдсан- {{ cancelledOrdersCount }}</span>
          </el-tab-pane>
          <el-tab-pane name="noPaidOrders">
            <span slot="label">Төлбөр төлөөгүй - {{ noPaidOrdersCount }}</span>
          </el-tab-pane>
          <el-tab-pane name="successOrders" >
            <span slot="label" name="successOrders">Амжилттай - {{ successOrdersCount }}</span>
          </el-tab-pane>
          <el-row :gutter="20">
            <el-col :span="6">
              <span style="display:block; text-align:center">Хайх</span>
              <el-input placeholder="Хайх ..." v-model="search" @change="getOrders"><i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
            </el-col>
            <el-col :span="8">
                <div style="display:inline-block">
                  <span style="display:block; text-align:center">Эхлэх огноо</span>
                  <el-date-picker
                  @change="getOrders"
                    v-model="start_date"
                    type="date"
                    placeholder="Эхлэх огноо"
                    value-format="yyyy-MM-dd"
                    style="margin-right:1rem"
                    :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
                <div style="display:inline-block">
                  <span style="display:block; text-align:center">Дуусах огноо</span>
                  <el-date-picker
                  @change="getOrders"
                    v-model="end_date"
                    type="date"
                    placeholder="Дуусах огноо"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
            </el-col>
            <el-col :span="6" v-if="order_status == 51 || order_status == 100">
              <span style="display:block; text-align:center">Жолооч</span>
              <el-select v-model="driver" @change="getOrders" filterable clearable placeholder="Жолоочоо сонгоно уу">
                <el-option
                  v-for="driver in drivers"
                  :key="driver.driver_name"
                  :label="driver.driver_name"
                  :value="driver.driver_name">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-tabs>
      </div>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="mt20 mb20 text-right"
      background
      :current-page.sync="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="pageTotal">
    </el-pagination>
    <el-table size="mini" :data="orderList"
        style="width: 100%"
        border
        v-loading="isLoading"
        element-loading-text="Уншиж байна..."
        element-loading-spinner="el-icon-loading"
        >
      <el-table-column label="Утас" width="120">
        <template slot-scope="scope">
          <div>{{ scope.row.user_phone }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Дугаар" prop="order_number" width="90" sortable></el-table-column>
      <el-table-column label="Хугацаа" width="220">
        <template slot-scope="scope">
          {{scope.row.promised_time}}
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="Захиалсан цаг" width="220" >
        <template slot-scope="scope">
          {{scope.row.createdAt}}
        </template>
      </el-table-column>
      <el-table-column prop="order_status" label="Төлөв" width="180"
         filter-placement="bottom-end">
        <template slot-scope="scope">
          {{scope.row.order_status}}
        </template>
      </el-table-column>
      <el-table-column prop="driver_name" label="Жолооч" width="150">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            <el-tag
              v-if="scope.row.driver_name == null"
              size="mini" type="danger">Хувиарлаагүй</el-tag>
            <!-- Жолооч хувиарласан -->
            <el-tag v-else size="mini" type="success" >
              {{ scope.row.driver_name }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Хаяг" prop="full_addr" width="650">
        <template slot-scope="scope">
          {{scope.row.address ? scope.row.address : scope.row.full_address}}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Явц" width="120">
        <template slot-scope="scope">
          <el-button type="default" size="mini" @click="showProcess(scope.row.id)">Явц харах</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pd-dialog-component :processData="processData" :progressIsShow="progressIsShow" :isPreOrder="isPreOrder" :currentTab="currentTab" :closeProgress="closeProgress"/>
  </div>
</template>
<script>
import _service from '@/helpers/services.js'
import pdDialogComponent from './components/ccProcessComponent.vue'
import { showNotif } from '@/helpers/helper.js'

export default {
  name: 'ccOrders',
  components: {
    pdDialogComponent
  },
  computed: {
    computedPage () {
      return this.$route.query.page
    },
    computedPageSize () {
      return this.$route.query.size
    }
  },
  watch: {
    computedPage () {
      this.getOrders()
    },
    computedPageSize () {
      this.getOrders()
    },
    currentTab (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getOrders()
      }
    }
  },
  created () {
    this.getOrders()
  },
  data: function () {
    return {
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: 'Өнөөдөр',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: 'Өчигдөр',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '7 хоног',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      start_date: '',
      end_date: '',
      drivers: '',
      driver: '',
      isPreOrder: false,
      loading: false,
      currentTab: 'prepareOrders',
      noPaidOrdersCount: 0,
      prepareOrdersCount: 0,
      cancelledOrdersCount: 0,
      driverOutOrdersCount: 0,
      successOrdersCount: 0,
      search: '',
      order_status: '',
      progressIsShow: false,
      orderStatus: 11,
      pageSize: 20,
      pageTotal: 0,
      currentPage: 1,
      orderList: [],
      isLoading: false,
      processData: {
        order_number: -1,
        isProcessShow: false,
        isTOKTOK: true
      }
    }
  },
  methods: {
    getOrders () {
      if (this.start_date && this.end_date) {
        if (this.start_date > this.end_date) {
          showNotif('info', 'Таны сонгосон хугацаагаар хайх боломжгүй')
          return false
        }
      }
      let incomingPage; let incomingOrderStatus; let from; let size
      if (this.currentTab) {
        switch (this.currentTab) {
          case 'noPaidOrders':
            incomingOrderStatus = 11
            break
          case 'prepareOrders':
            incomingOrderStatus = 13
            break
          case 'driverOutOrders':
            incomingOrderStatus = 51
            break
          case 'cancelledOrders':
            incomingOrderStatus = 91
            break
          case 'successOrders':
            incomingOrderStatus = 100
            break
          default:
            incomingOrderStatus = 11
        }
        this.order_status = incomingOrderStatus
        incomingPage = parseFloat(this.$route.query.page) || 1
        size = parseFloat(this.$route.query.size) || 20
        this.currentPage = incomingPage
        this.pageSize = size
        from = (this.pageSize * (incomingPage - 1))
        // this.$router.push({ name: 'ccOrders', query: { search_text: this.search, page: this.currentPage, size: this.pageSize } })
        this.getOrdersPD(from, size, incomingOrderStatus)
        this.getOrdersCount()
      }
    },
    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'ccOrders', query: { search_text: this.search, page: this.currentPage, size: this.pageSize } })
    },
    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'ccOrders', query: { search_text: this.search, page: this.currentPage, size: this.pageSize } })
    },
    getOrdersPD (from, size, orderStatus) {
      if (this.start_date == null) {
        this.start_date = ''
      } else if (this.end_date == null) {
        this.end_date = ''
      }
      const query = 'from=' + from + '&size=' + size + '&order_status=' + orderStatus + '&search_text=' + this.search + '&start_date=' + this.start_date + '&end_date=' + this.end_date + '&driver_name=' + this.driver
      this.isLoading = true
      _service.getOrdersCC(query).then(data => {
        this.pageTotal = data.total
        this.orderList = data.data.orderData
        this.drivers = data.data.drivers
        this.isLoading = false
      })
    },
    async getOrdersCount () {
      try {
        this.loading = true
        const query = 'search_text=' + this.search + '&driver_name=' + this.driver + '&start_date=' + this.start_date + '&end_date=' + this.end_date
        _service.getOrdersCount(query).then(res => {
          if (res.statusCode === 200) {
            this.noPaidOrdersCount = res.data.noPayCount
            this.prepareOrdersCount = res.data.preparingCount
            this.cancelledOrdersCount = res.data.cancelledCount
            this.driverOutOrdersCount = res.data.deliveredCount
            this.successOrdersCount = res.data.acceptedCount
            this.loading = false
          } else {
            this.loading = false
            throw new Error(res.error.message)
          }
        })
      } catch (err) {
        this.loading = false
        this.$notify.error({
          title: 'Алдаа',
          message: err.message
        })
      }
    },
    showProcess (id) {
      _service.getOrderDetailCC('id=' + id).then((data) => {
        if (data.status === 'success') {
          this.processData = data.data
          this.progressIsShow = true
        } else {
          this.$notify.error({
            title: 'Алдаа',
            message: data.error.message
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    closeProgress () {
      this.progressIsShow = false
    }
  }

}
</script>

<style>
#tab-prepareOrders.is-active{
    background: #E6A23C;
    color:black
  }
  #tab-driverOutOrders.is-active{
    background: #409EFF;
    color:inherit
  }
  #tab-cancelledOrders.is-active{
    background: #F56C6C;
    color:inherit
  }
  #tab-noPaidOrders.is-active{
    background: #909399;
    color:inherit
  }
  #tab-successOrders.is-active{
    background: #67C23A;
    color:inherit
  }
</style>
