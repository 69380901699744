<template>
  <div>
    <el-dialog :title="'Захиалгын дугаар : '  + (processData.order_number ? processData.order_number : '0' )" :visible="progressIsShow" @close="closeDialog()" width="900px" top="100px" class="process-dialog">
      <div v-loading="detailLoading">

        <div class="pb15 text-right">

          <el-button @click="showComplaintDialog(1)" size="mini" :loading="complaintButtonLoading" type="primary" :disabled ="mArrayIncludes(this.role, ['accountant','other','ST'])" >Санал бүртгэх</el-button>
          <el-button @click="showComplaintDialog(2)" size="mini" :loading="complaintButtonLoading" type="primary" :disabled ="mArrayIncludes(this.role, ['accountant','other','ST'])" >Гомдол бүртгэх</el-button>
          <el-button @click="cancelDialogIsShowTokTok = true" size="mini" type="danger">Захиалга цуцлах</el-button>
        </div>
        <div class="border-top mt10">
          <el-row :gutter="40" class="flext-height">
            <el-col :span="12" class="border-right">
              <div class="">
                <h3>Захиалгын дэлгэрэнгүй</h3>
                <ul class="list with-label">
                  <li>
                    <label>Захиалга авсан цаг  </label>
                    <p>{{processData.createdAt}}</p>
                  </li>
                  <li v-if="processData.advance_order == 'preorder'">
                    <label>Захиалга орох цаг</label>
                    <p>{{processData.kitchen_show_time}}</p>
                  </li>
                  <li>
                    <label>Захиалга өгөх цаг</label>
                    <p>{{processData.promised_time}}</p>
                  </li>
                  <li v-if="processData.driver_name">
                    <label >Жолооч</label>
                    <p>{{processData.driver_name}}</p>
                  </li>
                  <li>
                    <label>Утас</label>
                    <p>{{processData.user_phone}}</p>
                  </li>
                  <li v-if="processData.user_phone2">
                    <label >Утас 2</label>
                    <p>{{processData.user_phone2}}</p>
                  </li>
                  <li>
                    <label>Хаяг</label>
                    <p v-if="processData.full_address">{{processData.full_address}}</p>
                    <p v-if="processData.bairnote">Албан бус: {{processData.bairnote}}</p>
                    <p v-if="processData.addr_orts">Орц: {{processData.addr_orts}}</p>
                    <p v-if="processData.addr_orts_code">Орц код: {{processData.addr_orts_code}}</p>
                    <p v-if="processData.addr_haalga">Хаалга: {{processData.addr_haalga}}</p>
                    <p v-if="processData.addr_desc">Нэмэлт: {{processData.addr_desc}}</p>
                    <p >Тэмдэглэл: <el-input size="mini" type="textarea" placeholder="Please input" v-model="processData.note"></el-input> </p>
                    <el-button size="mini" type="primary" @click="changeOrderAddrNote()">Хаягын тэмдэглэл өөрчлөх</el-button>
                  </li>
                </ul>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="">
                <h3>Төлбөрийн мэдээлэл</h3>
                <ul class="list with-label">
                  <li>
                    <label>Төлбөрийн хэлбэр</label>
                    <p v-if="processData.order_status === 'Төлбөр төлөөгүй'">{{processData.order_status}}</p>
                    <p v-else v-for="(x, index) in processData.invoices" :key="index">{{x.payment_name}}</p>
                    </li>
                  <li>
                    <label>Төлбөр төлөгдсөн эсэх</label>
                    <p style="color:red;font-size: 14px;">{{processData.pay_status}}</p>
                  </li>
                  <li>
                    <label>Бүтээгдэхүүний дүн</label>
                    <p>{{processData.menu_total}}</p>
                  </li>
                  <li>
                    <label>Хөнгөлсөн дүн</label>
                    <p>{{parseInt(parseInt(processData.discount_total))}}</p>
                  </li>
                  <li>
                    <label>Хүргэлт</label>
                    <p>{{mformatPrice(processData.delivery_charge)}}₮</p>
                  </li>
                  <li>
                    <label>Нийт дүн</label>
                    <p>{{processData.pay_total}}</p>
                  </li>
                  <li>
                    <label>НӨАТ</label>
                    <p>{{processData.vat_type}}</p>
                  </li>
                  <li v-if="processData.vat_company_name">
                    <label>Байгууллага нэр</label>
                    <p>{{processData.vat_company_name}}</p>
                  </li>
                  <li v-if="processData.vat_regno">
                    <label >Регистр</label>
                    <p>{{processData.vat_regno}}</p>
                  </li>
                  <li>
                    <label>Бүтээгдэхүүнүүд</label>
                      <div  v-for="item in processData.items" :key="item.id">
                        <p>
                        <!-- <fa icon="angle-right" class="mr5"/> -->
                        ({{ item.item_count }}ш) --- {{ item.product_name }}  -- {{mformatPrice(parseInt(item.item_count * item.menu_price))}}₮</p>
                        <div v-if="item.items != 'null' && item.items != null">
                          <div v-if="JSON.parse(item.items).hasOwnProperty('items')">
                            <table>
                              <div style="margin-left:1.5em; margin-top:2px;" v-for="(child, index) in JSON.parse(item.items)['items']" :key="index">
                                <tr>
                                  <td>
                                ({{child.count}}ш) --- {{child.name_eng}}
                                </td>
                                  <td v-if="parseInt(child.add_price) !== 0"> +{{mformatPrice(parseInt(child.count * child.add_price))}}₮</td>
                                </tr>
                              </div>
                            </table>
                          </div>
                        </div>
                        <div v-else>
                        </div>
                      </div>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <div class="status-actions border-top border-bottom">
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()" size="mini">Цонхыг хаах</el-button>
      </span>
    </el-dialog>
    <order-cancel-dialog :isShow="cancelDialogIsShowTokTok" :orderData="processData" :closeFunction="closeCancelDialog"/>
    <ComplaintDialog :ComplaintData="typeOfDialog" :dialogType="typeOfDialog" :orderData="processData"/>
  </div>
</template>

<script>
import _service from '../../../../helpers/services.js'
import { formatPrice, showNotif, arrayIncludes } from '../../../../helpers/helper'
import ComplaintDialog from './ccDialogList'
import { getUser } from '../../../../utils/auth'
// import axios from 'axios'
import orderCancelDialog from './ccCancelOrder.vue'
export default {
  created () {
    getUser().then((user) => {
      this.loggedUser = user.attributes.email
      this.centerDialogVisible = false
      this.cancelOrderButton = false
      this.referOrderButton = false
      this.referButtonLoading = false
      this.ccConfirmButtonLoading = false
      this.cancelDialogIsShowTokTok = false
      this.convertDialogIsShowTokTok = false
      this.cancelOrderLoadingTokTok = false
      // this.$bus.on('closeProcessDialog', () => {
      //   this.closeDialog()
      // })
    })
  },
  mounted () {

  },
  computed: {
  },
  props: {
    processData: {
      type: Object
    },
    progressIsShow: {
      type: Boolean,
      default: false
    },
    processDataRefer: {
      type: String,
      default: ''
    },
    closeProgress: {
      type: Function
    },
    selectedTab: {
      type: String,
      default: ''
    },
    isPreOrder: {
      type: Boolean,
      default: false
    }
  },
  watch: {
  },
  components: { ComplaintDialog, orderCancelDialog },
  data: function () {
    return {
      note: '',
      cancelDialogIsShowTokTok: false,
      typeOfDialog: {
        isDialogShow: false,
        typeName: '',
        typeInt: 0
      },
      loading: true,
      role: 'employee',
      loggedUser: '',
      statusStep: 1,
      detailLoading: false,
      menu_total: '',
      pay_total: '',
      delivery_charge: '',
      order_id: '',
      statusCode: -1,
      err: null,
      complaintButtonLoading: false
    }
  },
  methods: {
    closeDialog () {
      this.closeProgress()
      this.statusStep = 0
      this.pay_total = ''
      this.menu_total = ''
      this.delivery_charge = ''
      this.processData.isTOKTOK = false
    },
    mformatPrice (value) {
      return formatPrice(value)
    },
    showComplaintDialog (state) {
      if (state === 2) {
        this.typeOfDialog.typeName = 'Гомдол'
      } else {
        this.typeOfDialog.typeName = 'Санал хүсэлт'
      }
      this.typeOfDialog.order_number = this.processData.order_number
      this.typeOfDialog.outlet_name = this.processData.outlet_name
      this.typeOfDialog.isDialogShow = true
      this.typeOfDialog.typeInt = state
    },
    changeOrderAddrNote () {
      this.order_id = this.processData.id
      this.note = this.processData.note
      const body = {
        order_id: this.order_id,
        note: this.note
      }
      _service.pdChangeOrderAddrNote(body)
        .then(response => {
          if (response.status === 'success') {
            this.closeDialog()
            showNotif('info', this.processData.order_number + ' захиалгын хаягын нэмэлт мэдээлэл өөрчлөгдлөө')
          } else {
            this.closeDialog()
            showNotif('error', this.processData.order_number + ' ' + response.error.message)
          }
        }).catch(error => {
          console.log('addr_note error', error)
          this.closeDialog()
          showNotif('error', this.processData.order_number + ' захиалгын хаягын нэмэлт мэдээлэл өөрчлөхөд алдаа гарлаа')
        })
    },
    mArrayIncludes (baseArray, searchArray) {
      return arrayIncludes(baseArray, searchArray)
    },
    closeCancelDialog () {
      this.cancelDialogIsShowTokTok = false
    }
  }
}
</script>
<style scoped>
.driver_suggest {
  font-weight: bold;
  color: #008000ad;
}
ul.list {
    list-style: none;
    padding-left: 0;
}
ul.list li {
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 12px;
}
ul.list li p {
  margin-top: 0;
  margin-bottom: 5px;
}
ul.list label {
  width: 120px;
  text-align: right;
  display: inline-block;
  font-weight: bold;
}
ul.with-label li{
  position: relative;
  padding-left: 140px;
}
ul.with-label label{
  position: absolute;
  left: 0;
}
</style>
