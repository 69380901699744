<template>
  <div >
    <el-dialog :title="dialogType.typeName + ' бүртгэх'" :visible.sync="dialogType.isDialogShow"  @open="onOpen" @opened="clearValidate(), getComplaintByType(dialogType.typeInt)" @closed="clearValidate()"  @close="onClose()" ref="dialog" top="100px" width="1400px">
        <el-row :gutter="40" style="display: flex" class="pt10 pb10">
            <el-col :span="8" class="custom-tree">
              <h3 class="pl20 mt0 mb10"><strong>Төрлүүд</strong></h3>
              <el-tree v-loading="complaintDialogLoading" :data="complaintDataList" :props="defaultProps" @node-click="handleComplaintNodeClick"></el-tree>
            </el-col>
            <el-col :span="16">
              <el-form label-position="top" :model="complaintPayload" :rules="rules" ref="dialogForm" size="mini">
                  <!-- <el-form-item :label="dialogType.typeName" prop="note"> -->
                  <el-form-item label="Тайлбар" v-if="currentComplaintData.description != null">
                      <el-input type="textarea" :disabled="true" :autosize="{ minRows: 4, maxRows: 6}" v-model="currentComplaintData.description"/>
                  </el-form-item>
                  <el-form-item label="Дэлгэрэнгүй" prop="description">
                      <el-input type="textarea" :maxlength="500" :autosize="{ minRows: 4, maxRows: 6}" v-model="complaintPayload.description"></el-input>
                  </el-form-item>
                  <el-radio-group v-model="complaintPayload.complained_type" @change="complaintPayload.complained_to = ''">
                    <el-radio :label="'default'" border>Үндсэн</el-radio>
                    <el-radio :label="'driver'" border>Жолооч</el-radio>
                    <el-radio :label="'outlet'" border>Салбар</el-radio>
                  </el-radio-group>
                  <el-row :gutter="20">
                      <el-col :span="8">
                          <el-form-item label="Захиалгын дугаар" prop="order_number">
                              <el-input v-model="complaintPayload.order_number" :maxlength="20" placeholder="Захиалгын дугаар" ></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :span="8" v-if="complaintPayload.complained_type == 'outlet'">
                          <el-form-item label="Салбар" prop="complained_to" >
                              <el-select v-model="complaintPayload.complained_to" placeholder="Салбар сонгох" filterable clearable="" >
                                  <el-option v-for="(item, index) in outlets" :key="index" :label="item.outlet_name" :value="item.outlet_name"> </el-option>
                              </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :span="8" v-if="complaintPayload.complained_type == 'driver'">
                        <el-form-item label="Жолооч сонгох" prop="complained_to">
                          <el-select  v-model="complaintPayload.complained_to" placeholder="Жолооч сонгох" filterable >
                            <el-option
                              v-for="item in riders"
                              :key="item.value"
                              :label="item.Username"
                              :value="item.Username">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                  </el-row>
              </el-form>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogType.isDialogShow = false && clearValidate" size="mini">Болих</el-button>
            <el-button type="success" :disabled="isBtnDisabled" @click="saveSuggest('dialogForm')" size="mini">Хадгалах</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import _service from '../../../../helpers/services'
import { getUser } from '../../../../utils/auth'
import { mapGetters } from 'vuex'
export default {
  created () {
    getUser().then((user) => {
      this.loggedUser = user.attributes.email
    })
  },
  mounted () {
    this.getAllOutletNames()
    this.getRiderUser()
  },
  computed: {
    ...mapGetters({
      RTDBDrivers: 'fbase/RTDBDrivers'
    }),
    rules () {
      return {
        description: [
          { required: true, message: 'Тайлбар бичнэ үү.', trigger: 'blur' }
        ],
        complained_to: [
          { required: this.complained_type !== 'default', message: 'Сонгоно уу', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    dialogType: {
      type: Object
    },
    orderData: {
      type: Object
    }
  },
  data: function () {
    return {
      loggedUser: '',
      riders: [],
      ruleType: false,
      isEmitWorked: false,
      // isDialogShow: false,
      complaintDataList: null,
      isBtnDisabled: false,
      complaintDialogLoading: false,
      outlets: [],
      radio: 1,
      branches: [
        {
          value: 'food',
          name: 'Хоол'
        },
        {
          value: 'goods',
          name: 'Ахуйн бараа'
        },
        {
          value: 'drinks',
          name: 'Шингэн хүнс'
        },
        {
          value: 'grocery',
          name: 'Хүнс'
        },
        {
          value: 'gift',
          name: 'Бэлэг'
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      dialogTitle: '',
      typeName: '',
      complaintType: null,
      complaintPayload: {
        type: null,
        created_user: '',
        description: '',
        order_number: '',
        result: false,
        phone: null,
        outlet_name: '',
        complaint_id: '',
        driver_name: '',
        complained_type: 'default',
        complained_to: ''
        // driver_uid: ''
      },
      complaint: '',
      currentComplaintData: {},
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  methods: {
    async getRiderUser () {
      const query = '?name=' + this.search + '&status=' + this.statusValue
      await _service.getRiderUsers(query).then(response => {
        if (response.status === 'success') {
          this.riders = response.data.Users
        }
      })
    },
    getAllOutletNames () {
      _service.getAllOutletName().then(response => {
        if (response.status === 'success') {
          this.outlets = response.data.sort((a, b) => (a.outlet_name > b.outlet_name) ? 1 : ((b.outlet_name > a.outlet_name) ? -1 : 0))
        } else {
          this.showNotif('Салбарууд авч чадсангүй')
        }
      }).catch(error => {
        console.log('getAllOutletNames error', error)
      })
    },
    showNotif (type, message) {
      if (type === 'success') {
        this.$notify.success({
          title: 'Амжилттай',
          message: message
        })
      } else {
        this.$notify.error({
          title: 'Алдаа',
          message: message
        })
      }
    },
    clearValidate () {
      this.$refs.dialogForm.clearValidate()
    },
    handleComplaintNodeClick (data) {
      if (data.children != null) {
        this.isBtnDisabled = true
      } else {
        this.isBtnDisabled = false
      }
      this.complaintPayload.complaint_id = data.id
      this.complaintPayload.is_show_kitchen = data.is_show_kitchen
      this.complaint = data.name
      this.currentComplaintData = data
    },
    getComplaintByType (type) {
      this.complaintDialogLoading = true
      this.isBtnDisabled = true
      if (type === 1) {
        this.ruleType = false
        this.isComplaint = true
        this.complaintType = 1
      } else {
        this.ruleType = true
        this.isComplaint = false
        this.complaintType = 2
      }
      if (type === 2) {
        _service.getPdComplaintTypes().then((response) => {
          if (response.status === 'success') {
            this.complaintDataList = response.response
          }
          this.complaintDialogLoading = false
        }).catch((err) => {
          this.showNotif(err, 'Сервертэй холбогдоход алдаа гарлаа')
          this.addDialogVisible = false
          this.complaintDialogLoading = false
        })
      } else {
        _service.getPdSuggestTypes().then((response) => {
          if (response.status === 'success') {
            this.complaintDataList = response.response
          }
          this.complaintDialogLoading = false
        }).catch((err) => {
          this.showNotif(err, 'Сервертэй холбогдоход алдаа гарлаа')
          this.addDialogVisible = false
          this.complaintDialogLoading = false
        })
      }
    },
    saveSuggest (data) {
      this.isBtnDisabled = true
      this.complaintPayload.created_user = this.loggedUser
      this.complaintPayload.phone = this.orderData.user_phone

      if (this.isComplaint) {
        // Sanal
        this.$refs[data].validate((valid) => {
          if (valid) {
            this.addDialogVisible = false
            var loadingInstance = this.$loading(
              {
                target: this.$refs.dialog.$el.querySelector('.el-dialog')
              })
            _service.registerSuggest(this.complaintPayload).then(response => {
              if (response.status === 'success') {
                this.showNotif('success', 'Амжилттай бүртгэлээ')
                this.dialogType.isDialogShow = false
                loadingInstance.close()
              } else {
                this.showNotif('err', response.error.message)
                loadingInstance.close()
              }
              this.isBtnDisabled = false
            }).catch(error => {
              this.showNotif('err', 'gomdol' + error)
              this.isBtnDisabled = false
            })
            this.addDialogVisible = false
          } else {
            return false
          }
        })
      } else {
        // Gomdol
        this.$refs[data].validate((valid) => {
          if (valid) {
            var loadingInstance = this.$loading(
              {
                target: this.$refs.dialog.$el.querySelector('.el-dialog')
              })
            _service.registerComplaint(this.complaintPayload).then(response => {
              if (response.status === 'success') {
                this.showNotif('success', 'Амжилттай бүртгэлээ')
                this.dialogType.isDialogShow = false
              } else {
                this.showNotif('err', response.error.message)
              }
              loadingInstance.close()
              this.isBtnDisabled = false
            }).catch(error => {
              this.showNotif('err', 'gomdol' + error)
              this.isBtnDisabled = false
              loadingInstance.close()
            })
            this.addDialogVisible = false
          } else {
            return false
          }
        })
      }
    },
    onOpen () {
      if (this.dialogType.order_number !== '' && this.dialogType.order_number !== undefined) this.complaintPayload.order_number = JSON.parse(JSON.stringify(this.dialogType)).order_number
      if (this.dialogType.outlet_name !== '' && this.dialogType.outlet_name !== undefined) this.complaintPayload.outlet_name = JSON.parse(JSON.stringify(this.dialogType)).outlet_name
    },
    onClose () {
      this.clearValidate()
      this.radio = 1
      this.complaintPayload = {
        type: null,
        created_user: '',
        description: '',
        order_number: '',
        result: false,
        phone: null,
        outlet_name: '',
        complaint_id: '',
        complained_type: 'default',
        complained_to: ''
      }
    }
  }
}
</script>
